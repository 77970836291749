import { NavigationStep } from "./types";
import { NewSlimCompassLinear, NewSlimHomeLinear, NewSlimInfoLinear, NewSlimProfileLinear, NewSlimSearchTicketLinear, } from '@s7/icons';
var PROFILE_DOMAIN = process.env.REACT_APP_PROFILE_DOMAIN;
export var items = [
    {
        title: 'com.s7.tab.navigation.main.page',
        value: NavigationStep.MAIN,
        href: '/',
        icon: NewSlimHomeLinear
    },
    {
        title: 'com.s7.tab.navigation.trips.page',
        value: NavigationStep.TRIPS,
        href: "/?tab=".concat(NavigationStep.TRIPS),
        icon: NewSlimCompassLinear
    },
    {
        title: 'com.s7.tab.navigation.search.page',
        value: NavigationStep.SEARCH,
        href: "/?tab=".concat(NavigationStep.SEARCH),
        icon: NewSlimSearchTicketLinear
    },
    {
        title: 'com.s7.tab.navigation.info.page',
        value: NavigationStep.INFO,
        href: "/?tab=".concat(NavigationStep.INFO),
        icon: NewSlimInfoLinear
    },
    {
        title: 'com.s7.tab.navigation.profile.page',
        value: NavigationStep.PROFILE,
        href: PROFILE_DOMAIN,
        icon: NewSlimProfileLinear
    },
];
export var LITE_PATH = '/lite/';
