import React from 'react';
import { injectIntl } from '@s7ui-kit/s7ui-kit';
import { TabNavigation as TabNavigationUI } from '@s7/ui';
import { CLASSNAME_FULL_WIDTH } from '@s7/base';
import { useLocation } from 'react-router-dom';
import { items, LITE_PATH } from '../constants';
import { sendClickTabNavigationItem } from '../analytics';
import { NavigationStep } from '../types';
import { Affix } from '@s7/layout';
import { getDefaultZIndex } from '@s7/styles';
var wrapperSx = function () { return ({
    width: '100%',
    zIndex: getDefaultZIndex('app')
}); };
export var TabNavigation = injectIntl(function (_a) {
    var words = _a.words, getMessage = _a.getMessage;
    var pathname = useLocation().pathname;
    var activeTab = pathname.includes(LITE_PATH) ? NavigationStep.SEARCH : null;
    return (React.createElement(Affix, { sx: wrapperSx, position: { bottom: 0, left: 0 } },
        React.createElement(TabNavigationUI, { className: CLASSNAME_FULL_WIDTH, activeTab: activeTab }, items.map(function (item) { return (React.createElement(TabNavigationUI.Item, { key: item.value, as: 'a', href: item.href, icon: item.icon, title: getMessage(words, item.title), value: item.value, onClick: sendClickTabNavigationItem(NavigationStep.PAGE, item.value) })); }))));
});
